import "../LandingPage/LandingPage.scss";
import { GITHUB_ASSETS_HOST_URL } from "../../helpers/api";

// IMAGE

import { Link, NavLink } from "react-router-dom";
import React from "react";

const SiteFooter = () => {
  return (
    <>
      <div className="landing-footer-alignment">
        <div className="cus-container">
          <div className="footer-grid">
            <div className="footer-grid-items">
              <NavLink className="footer-logo-center" to="/dashboard">
                {/* <img src={MeekoLogo} alt="footer-logo" /> */}
                <img
                  src={
                    GITHUB_ASSETS_HOST_URL + `MeekoAssets/footer-white-logo.png`
                  }
                  alt="footer-logo"
                />
              </NavLink>
              <div className="location-text">
                <p>Learn. Play. Teach. Explore</p>
              </div>
            </div>

            <div className="footer-grid-items footer_second_col">
              <ul>
                <li>
                  <Link
                    to={`${
                      localStorage.getItem("role") === "ELearner"
                        ? "/learner"
                        : localStorage.getItem("role") === "Parent"
                        ? "/parent"
                        : localStorage.getItem("role") === "Educator"
                        ? ""
                        : ""
                    }/about-us`}
                  >
                    About us
                  </Link>
                </li>

                <li>
                  <Link
                    to={`${
                      localStorage.getItem("role") === "ELearner"
                        ? "/learner"
                        : localStorage.getItem("role") === "Parent"
                        ? "/parent"
                        : localStorage.getItem("role") === "Educator"
                        ? ""
                        : ""
                    }/contact-us`}
                  >
                    Contact us
                  </Link>
                </li>

                <li>
                  <Link
                    to={`${
                      localStorage.getItem("role") === "ELearner"
                        ? "/learner"
                        : localStorage.getItem("role") === "Parent"
                        ? "/parent"
                        : localStorage.getItem("role") === "Educator"
                        ? ""
                        : ""
                    }/blog`}
                  >
                    {" "}
                    Blog
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to={`${
                      localStorage.getItem("role") === "ELearner"
                        ? "/learner"
                        : localStorage.getItem("role") === "Parent"
                        ? "/parent"
                        : localStorage.getItem("role") === "Educator"
                        ? ""
                        : ""
                    }/policies/terms-and-conditions-meeko-users`}
                  >
                    {" "}
                    Terms & Conditions
                  </Link>
                </li> */}

                <li>
                  <Link
                    to={`${
                      localStorage.getItem("role") === "ELearner"
                        ? "/learner"
                        : localStorage.getItem("role") === "Parent"
                        ? "/parent"
                        : localStorage.getItem("role") === "Educator"
                        ? ""
                        : ""
                    }/policies/privacy-policy`}
                  >
                    Privacy Policy
                  </Link>
                </li>

                <li>
                  <Link
                    to={`${
                      localStorage.getItem("role") === "ELearner"
                        ? "/learner"
                        : localStorage.getItem("role") === "Parent"
                        ? "/parent"
                        : localStorage.getItem("role") === "Educator"
                        ? ""
                        : ""
                    }/help-desk`}
                  >
                    FAQ's
                  </Link>
                </li>
              </ul>

              <p>
                {/*moment().year()*/}
                {/* 2021 Meeko Enterprises Private Limited */}
                2023 © MEEKO Enterprises Private Limited. All Rights Reserved.
                Above mentioned contents are subject to change without notice
                and are copyrighted by Meeko Enterprises Private Limited.
              </p>
            </div>
            <div className="footer-grid-items footer_last_col">
              <div className="Social_Icon">
                <a
                  href="https://www.facebook.com/meeko.learning"
                  target="_blank"
                >
                  {/* <img src={Facebook} alt="Facebook" /> */}
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/explor_class/Asset 1.png`
                    }
                    alt="Facebook"
                  />
                </a>

                <a
                  href="https://www.youtube.com/channel/UCr_xCn0bhLW1dd4ZC3zo09w?sub_confirmation=1"
                  target="_blank"
                >
                  {/* <img src={Youtube} alt="Youtube" /> */}
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/explor_class/Asset 2.png`
                    }
                    alt="Youtube"
                  />
                </a>

                <a
                  href="https://www.instagram.com/meeko.world/"
                  target="_blank"
                >
                  {/* <img src={Instagram} alt="Instagram" /> */}
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/explor_class/Asset 3.png`
                    }
                    alt="Instagram"
                  />
                </a>

                <a href="https://in.pinterest.com/" target="_blank">
                  {/* <img src={Pintrest} alt="pintrest" /> */}
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/explor_class/Asset 4.png`
                    }
                    alt="pintrest"
                  />
                </a>

                <a href="https://twitter.com/MeekoWorld" target="_blank">
                  {/* <img src={Twitter} alt="twitter" /> */}
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/explor_class/Asset 5.png`
                    }
                    alt="twitter"
                  />
                </a>

                <a href="https://www.snapchat.com/" target="_blank">
                  {/* <img src={Snapchat} alt="Snapchat" /> */}
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/explor_class/Asset 6.png`
                    }
                    alt="Snapchat"
                  />
                </a>

                <a href="https://web.telegram.org/z/" target="_blank">
                  {/* <img src={Telegram} alt="Telegram" /> */}
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/explor_class/Asset 7.png`
                    }
                    alt="Telegram"
                  />
                </a>

                <a
                  href="https://www.kooapp.com/profile/meeko.world/5051c392-d474-4fa9-ac39-561de73d688f/Koos"
                  target="_blank"
                >
                  {/* <img src={Koo} alt="Koo" /> */}
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/explor_class/Asset 8.png`
                    }
                    alt="Koo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SiteFooter);
